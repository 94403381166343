<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>
                <router-link :to="{ name: 'company.point.list' }">
                  {{ $t('point.point_management') }}
                </router-link>
              </li>
              <li>{{ $t('point.edit_point_type') }}</li>
            </ul>
          </nav>
        </div>
        <div class="uk-alert-success" uk-alert v-if="successMessage">
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i class="uil-exclamation-triangle"></i> {{ successMessage }}
          </p>
        </div>
        <div class="uk-alert-danger" uk-alert v-if="errorMessage">
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ errorMessage }}
          </p>
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <h4><i class="uil-question-circle"></i> {{ $t('point.edit_point_type') }} </h4>
            <div class="row">
              <div class="col-xl-9 m-auto">
                <form action="">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="point_type"
                      >{{ $t('point.point_type') }} <span class="required">*</span></label
                    >
                    <div class="col-md-9">
                      <select
                        class="selectpicker21"
                        id="selectpicker"
                        disabled
                      >
                        <option
                          v-for="category in points"
                          :key="category.id"
                          :value="category.id"
                          :selected="category.id == formattedItem.point_category_id"
                        >
                          {{ $t(category.key_name) }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="point"
                      >{{ $t('point.point') }} <span class="required">*</span></label
                    >
                    <div class="col-md-9">
                      <input
                        type="number"
                        class="form-control"
                        v-model="formattedItem.point"
                        placeholder="Puan giriniz"
                        required=""
                      />
                    </div>
                  </div>

                  <div class="form-group row mb-2">
                    <label class="col-md-3 col-form-label" for="status">
                      {{ $t('general.status') }}</label
                    >
                    <div class="col-md-9">
                      <toggle-button
                        :width="80"
                        v-model="is_status"
                        color="#ae71ff"
                        :sync="true"
                        :labels="{ checked: 'Aktif', unchecked: 'Pasif' }"
                      />
                    </div>
                  </div>

                  <div class="uk-card-footer float-right pb-0 pr-0">
                    <router-link
                      class="uk-button-default mr-2 uk-button small"
                      :to="{ name: 'company.point.list' }"
                    >
                      {{ $t('general.back') }}
                    </router-link>
                    <button
                      class="uk-button uk-button-primary small"
                      type="button"
                      @click="addItem"
                    >
                      <i class="icon-feather-arrow-right"></i> {{ $t('general.save') }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services/index";
import DataTable from "@/assets/components/dataTable/DataTable";
import modulePoint, {
  BASE_URL as POINT_BASE_URL,
  GET_ITEMS as POINT_GET_ITEMS,
  MODULE_NAME as POINT_MODULE_NAME,
  ITEMS as POINT_ITEMS,
} from "@/core/services/store/point_category.module";
import module, {
  BASE_URL,
  UPDATE_ITEM,
  GET_ITEM,
  ITEM,
  MODULE_NAME,
  ERROR,
} from "@/core/services/store/point.module";
export default {
  name: "QuestionAdd",
  components: { DataTable },
  data() {
    return {
      is_status: false,
      selected_point: 1,
      given_point: 0,
      errorMessage: null,
      successMessage: null,
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(POINT_MODULE_NAME, modulePoint);
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    points: {
      get() {
        return store.getters[POINT_MODULE_NAME + "/" + POINT_ITEMS];
      },
      set(value) {},
    },
    item: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) {},
    },
    formattedItem: {
      get() {
          this.is_status = (this.item.status)?true:false
          return this.item;
      }
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {},
    },
  },
  methods: {
    pointResult() {
      this.$store.dispatch(POINT_MODULE_NAME + "/" + POINT_GET_ITEMS, {
        url: POINT_BASE_URL,
      });
    },

    itemResult() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEM, {
        url: BASE_URL + "/" + this.$route.params.id,
      });
    },
    addItem() {
      let self = this;
      if (!self.formattedItem.point || self.formattedItem.point < 0) {
        this.errorMessage = this.$t("validation.required_field").replace(":NAME", this.$t("point.point"));
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
      }
      let formData = {
        point: self.formattedItem.point,
        status: self.is_status == true ? 1 : 0,
      };
      store
        .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id,
          contents: formData,
        })
        .then(() => {
          if (!this.errors) {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            this.successMessage = this.$t("general.successfully_created");
            this.$router.push({ name: "company.point.list" });
          }
        });
    },
    resetMessage() {
      this.errorMessage = null;
      this.successMessage = null;
    },
  },
  mounted() {
    this.pointResult();
    this.itemResult();
  },
  created() {
    this.debouncedResetMessages = _.debounce(this.resetMessage, 1500);
  },
  watch: {
    points(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker21").selectpicker();
        }, 500);
      }
    },
    successMessage: function (val) {
      this.debouncedResetMessages();
    },
    errorMessage: function (val) {
      this.debouncedResetMessages();
    },
  },
};
</script>
<style scoped>
.selected_questions {
  color: #ae71ff;
  border: 1px solid #eaeaea;
  display: block;
  font-size: 13px;
  line-height: 33px;
  padding: 1px 7px;
  border-radius: 8px;
}
</style>